<template>
  <div id="DetailClientQuizionaryPage">
    <c-text
      color="brand.900"
      font-weight="bold"
      font-size="18px"
      line-height="150%"
    >
      Kuesioner
    </c-text>
    <c-text font-weight="bold" font-size="24px" line-height="150%" color="#111">
      Program Body Goals
    </c-text>

    <c-flex
      w="100%"
      mt="30px"
      justifyContent="space-between"
      alignItems="center"
      v-chakra="{
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginRight: '0',
          },
          '&.done': {
            backgroundColor: 'brand.900',
          },
        },
      }"
    >
      <c-box
        v-for="(_, index) in 7"
        :key="index"
        class="step"
        :class="{ done: index < page }"
      />
    </c-flex>

    <c-text
      v-if="selectedQuizionary"
      mt="20px"
      font-weight="bold"
      font-size="18px"
      line-height="150%"
    >
      {{ selectedQuizionary.title }}
    </c-text>

    <QuizionaryDetail
      v-if="selectedQuizionary && page !== 5"
      :data="selectedQuizionary.data"
      v-chakra
      mt="20px"
    />
    <template v-if="selectedQuizionary && page === 5">
      <Quizionary5Detail
        v-for="item in selectedQuizionary.data"
        :key="item.value.id"
        :animal-side-dish="item.value.animalSideDish"
        :category="item.value.category"
        :drink="item.value.drink"
        :fruit="item.value.fruit"
        :photo-url="item.value.photoUrl"
        :snack="item.value.snack"
        :staple-food="item.value.stapleFood"
        :vegetable="item.value.vegetable"
        :vegetable-side-dish="item.value.vegetableSideDish"
        v-chakra
        mt="10px"
      />
    </template>

    <hr v-if="isDevelopment" v-chakra mt="30px" w="full" />
    <QuizionaryDetail
        v-if="selectedQuizionary && selectedQuizionary.dinamis"
        :data="selectedQuizionary.dinamis"
        v-chakra
        mt="20px"
    />

    <c-box d="flex" mt="30px" justify-content="space-between">
      <c-button
        d="flex"
        grid-gap="5px"
        px="20px"
        rounded="full"
        variant-color="brand"
        :is-disabled="page === 1"
        as="router-link"
        :to="{ query: { page: page - 1 } }"
      >
        <c-icon name="chevron-left" size="24px" />
        <c-text>Kembali</c-text>
      </c-button>
      <c-button
        d="flex"
        grid-gap="5px"
        px="20px"
        rounded="full"
        variant-color="brand"
        :is-disabled="page === 7"
        as="router-link"
        :to="{ query: { page: page + 1 } }"
      >
        <c-text>Selanjutnya</c-text>
        <c-icon name="chevron-right" size="24px" />
      </c-button>
    </c-box>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { sortMealCategory } from "@/utils/sort-meal-category"
import _ from "lodash"
import QuizionaryDetail from "@/components/quizionary/detail.vue"
import Quizionary5Detail from "@/components/quizionary/detail-kuis-5.vue"
import quizionaryLabelMap from "@/utils/quizionary-label-map"
import quizionaryValueMap from "@/utils/quizionary-value-map"

export default {
  name: "NutriQuizionaryDetail",
  components: {
    QuizionaryDetail,
    Quizionary5Detail,
  },
  data() {
    return {
      kuisioner: null,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    page() {
      return +(this.$route.query.page ?? 1)
    },
    selectedQuizionary() {
      return this.quizionary[this.page]
      // return null;
    },
    quizionary() {
      if (this.kuisioner == null) return {}

      let page5 = [...this.kuisioner.foodRecords].sort((a, b) =>
        sortMealCategory(a.category, b.category)
      )

      // eslint-disable-next-line no-unreachable
      return {
        1: {
          title: "Identitas & Data Umum",
          data: Object.entries(_.omit(this.kuisioner.generalData, "id")).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            })
          ),
          dinamis: this.getDinamisQuestionFor("general_data"),
        },
        2: {
          title: "Data Antropometri (Ukuran & Komposisi Tubuh)",
          data: Object.entries(
            _.omit(this.kuisioner.anthropometryData, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor("anthropometry_data"),
        },
        3: {
          title: "Data Pola & Riwayat Makan",
          data: Object.entries(
            _.omit(this.kuisioner.dietAndEatingHistory, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor("diet_and_eating_history"),
        },
        4: {
          title: "Data Aktivitas Harian",
          data: Object.entries(
            _.omit(this.kuisioner.dailyActivities, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor("daily_activities"),
        },
        5: {
          title: "Food Record 24 Jam",
          data: Object.entries(_.omit(page5, "id")).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            })
          ),
          dinamis: this.getDinamisQuestionFor("food_records"),
        },
        6: {
          title: "Kemampuan Aktivitas Fisik",
          data: Object.entries(
            _.omit(this.kuisioner.physicalActivityAbilities, "id")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor("physical_activity_abilities"),
        },
        7: {
          title: "Kondisi Pribadi & Riwayat Medis",
          data: Object.entries(
            _.omit(this.kuisioner.medicalHistory, "id", "specialMedicine")
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor("medical_history"),
        },
      }
    },
  },
  async mounted() {
    this.kuisioner = await this.$store.dispatch(
      "kuisioner/getV2NutriAnswers",
      this.programId
    )
  },
  methods: {
    getDinamisQuestionFor(category) {
      return this.kuisioner.answers
        .filter((it) => it.questions.category === category)
        .map((item, index) => ({
          order: index,
          label: item.question,
          value: item.answer,
        }))
    },
  },
}
</script>
